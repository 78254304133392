@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Raleway", sans-serif;
  background-color: #141414;
}
.header-bg {
  /* background-image: url("https://sudipto-data.s3.amazonaws.com/website+background+video.dfc85806-min.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.ant-collapse-item {
  border: 1px solid #141414 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  background-color: #252533 !important;
  filter: drop-shadow(0px 3.91185px 19.5593px #141414) !important;
  -webkit-filter: drop-shadow(0px 3.91185px 19.5593px #141414) !important;
}

.ant-collapse-header {
  padding: 20px 20px !important;
  background-color: #252533 !important;
  color: #fff !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
@media only screen and (max-width: 768px) {
}
